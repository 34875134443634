
import { copySelectElementContents } from '@mobro/libs';
import { Message } from 'element-ui';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProseCreate from './index.vue';

@Component({})
export default class ToolTips extends Vue {
    @Prop() newsCount!: number;

    async onCopyTemplate() {
        const el = document.querySelector('#template') as HTMLElement;
        await copySelectElementContents(el);
        Message.success('模板已复制到粘贴板~~');
        (this.$parent as ProseCreate).formData.articleText = '';
    }
}
